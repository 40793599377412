.home {
  width: 100%;
  height: 100%;
  position: relative;
}

.arrow {
  color: #fff;
  font-size: 4em;
  position: absolute;
  bottom: 1em;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate3d(-50%, 0, 0);
  }
  40% {
    transform: translate3d(-50%, -30px, 0);
  }
  60% {
    transform: translate3d(-50%, -15px, 0);
  }
}