.nav-item {
  margin: 10px 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  color: #000;
  font-size: 1.5em;
  letter-spacing: 0.1em;
}

.nav-item a,
.nav-item a:link
.nav-item a:visited {
  color: #000;
  padding: 1em 1.5em;
  border: 3px solid rgb(0, 0, 0, 0);
  text-decoration: none;
}

.nav-item a:hover,
.nav-item a:active,
.nav-item a.active {
  border: 3px solid #000;
}