.greeting-hello {
  font-size: 4em;
  font-weight: 700;
  line-height: 1.5em;
}

.greeting-description {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  opacity: 0.7;
}

.cursor-white {
  color: #fff;
}