.root {
  background-color: #fff;
  color: #000;
  padding: 0 5em;
  text-align: left;
  max-width: 1080px;
  box-sizing: border-box;
  display: block;
  margin: auto;
}

.root h2 {
  padding-top: 150px;
  text-transform: uppercase;
}

.root h3 {
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 0px;
  text-align: center;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.flex-container-reverse {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  justify-content: space-around;
}

.flex-container .description {
  margin-left: 4em;
}

.flex-container-reverse .description {
  margin-right: 4em;
}

.card {
  background: #fff;
  border-radius: 10px;
  display: inline-block;
  width: 400px;
  height: 400px;
  margin: 4em 0;
  position: relative;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-content {
  display: block;
  margin: 0;
  text-indent: -9999px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url('../../assets/Skills/Languages/C++.png');
  background-size: cover;
  border-radius: 10px;
}

.description {
  max-width: 400px;
}
