.root {
  background-color: #fff;
  color: #000;
  padding: 0 5em;
  text-align: left;
  max-width: 1080px;
  box-sizing: border-box;
  display: block;
  margin: auto;
}

.root h2 {
  padding-top: 150px;
  text-transform: uppercase;
}

.root h3 {
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 100px;
  text-align: center;
}

.quick-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.family-picture {
  border-radius: 10px;
}

.family-picture-description {
  background-color: #ef476f !important;
  top: 95% !important;
  border-radius: 10px !important;
  font-size: 1.5em !important;
}