.root {
  background-color: #fff;
  color: #000;
  padding: 0 5em;
  text-align: left;
  max-width: 1080px;
  box-sizing: border-box;
  display: block;
  margin: 0 auto 150px auto;
}

.root h2 {
  padding-top: 150px;
  text-transform: uppercase;
}

.root h3 {
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 100px;
  text-align: center;
}

.root strong {
  color: #ef476f;
}