.navbar {
  width: 100%;
  min-height: 6vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  animation: navbar-in 0.5s ease-out 1.5s forwards;
}

.small-logo {
  width: 50px;
  margin: 25px;
}

.small-logo:hover {
  cursor: pointer;
}

.navbar ul {
  width: 60em;
  margin: 0 25px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline-start: 0;
}

@keyframes navbar-in {
  to {
    opacity: 1;
  }
}
