.Logo {
  height: 25vmin;
  border: 1px solid transparent;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  fill: none;
  stroke: #fff;
  transform-origin: center;
}

.LogoG {
  stroke-width: 32;
}
.LogoC {
  stroke-width: 64;
}

.PowerOff {
  animation: breathe 3s ease-in-out infinite;
}

.PowerOn {
  animation: rotate90 1s ease-out forwards, bounceOut 0.75s linear 2.5s forwards;
}

.PowerOn .LogoG {
  stroke: #fff;
  stroke-dasharray: 1100;
  stroke-dashoffset: 1100;
  animation: fillG 1s linear forwards;
}

.PowerOn .LogoC {
  stroke: #fff;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  animation: fillC 0.5s linear 1s forwards;
}

@keyframes breathe {
  0% {
    transform: scaleX(1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes rotate90 {
  to {
    transform: rotate(90deg);
  }
}

@keyframes fillG {
  to {
    stroke-dashoffset: 2200;
  }
}

@keyframes fillC {
  to {
    stroke-dashoffset: 1600;
  }
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  100% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate(-120deg);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
