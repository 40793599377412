.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 5px solid #fff;
  background-color: #fff;
  margin-top: 35vh;
  margin-bottom: 2em;
  opacity: 0;
  animation: pfp-in 0.5s ease-out 2s forwards;
}

@keyframes pfp-in {
  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
  100% {
    opacity: 1;
  }
}
