.app {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  box-sizing: border-box;
  background-image: url('./assets/background.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-black {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #000;
}

.background-reveal {
  animation: reveal 2s forwards;
}

@keyframes reveal {
  to {
    background: rgba(0, 0, 0, 0);
  }
}

.footer {
  width: 100%;
  height: 1px;
}