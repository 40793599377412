.root {
  background-color: #fff;
  color: #000;
  padding: 0 5em;
  text-align: left;
  max-width: 1080px;
  box-sizing: border-box;
  display: block;
  margin: auto;
}

.root h2 {
  padding-top: 150px;
  text-transform: uppercase;
}

.root h3 {
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.icon-container {
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.icon {
  display: block;
  margin: 20px;
  text-indent: -9999px;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  background-image: url('../../assets/Skills/Languages/C++.png');
  background-size: cover;
  border-radius: 15px;
  transition: all .2s ease-in-out;
}

.icon:hover {
  transform: scale(1.1);
}